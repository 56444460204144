import React from 'react'

export default function TopBar() {
  return (
    <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
                <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:info@barakahbyte.in">info@barakahbyte.in</a></i>
                <i className="bi bi-phone d-flex align-items-center ms-4"><a href="tel:+919797033565">+91 9797033565</a></i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
                <button className="btn btn-sm btn-primary"><i className="bi bi-twitter"></i></button>
                <button className="btn btn-sm btn-primary"><i className="bi bi-facebook"></i></button>
                <button className="btn btn-sm btn-primary"><i className="bi bi-instagram"></i></button>
                <button className="btn btn-sm btn-primary"><i className="bi bi-linkedin"></i></button>
            </div>
        </div>
    </section>
  )
}
