import React, { useId, useState } from 'react'
import { Link } from 'react-router-dom'

export default function RequestQuote() {
    const [formMsg, setFormMsg] = useState('');
    const refNo = useId();
    const validation = (data)=>{
        if(data.name === '' || data.name.length < 4){
            return false;
        }
        if(data.countryCode === '' || data.countryCode.length !== 3){
            return false;
        }
        if(data.mobNo === '' || data.mobNo.length !== 10){
            return false;
        }
        if(data.emailId === '' || data.name.emailId < 10){
            return false;
        }
        return true;
    }
    function handleQuoteForm(event){
        event.preventDefault();
        const data = Object.fromEntries(new FormData(event.target).entries());
        const successMsg  = "Thank You For Requesting Quote, Kindly Contact +91 9797033565 and Provide Ref No: ("+refNo+data.productQuote+")";
        validation(data)?setFormMsg(successMsg):setFormMsg('Kindly Resolve Form Errors');
    }
  return (
    <>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                    <h2>Request Quote</h2>
                    <ol>
                        <li><Link to="/">Home</Link></li>
                        <li>Quote</li>
                    </ol>
                </div>

            </div>
        </section>
        <section className="inner-page">
            <div className="container">
                <div className='text-success mb-3'>{formMsg}</div>
                <form onSubmit={handleQuoteForm}>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" name="name" id="name" placeholder="Full Name" required maxLength={'40'} />
                        <label htmlFor="name">Client Name <span className="text-danger">*</span></label>
                    </div>
                    <div className="input-group mb-3">
                        <div className="form-floating">
                            <input type="text" className="form-control" name="countryCode" id="countryCode" 
                                placeholder="Country Code" defaultValue={'+91'}  required pattern='\+[0-9]{2}' maxLength={'3'} />
                            <label htmlFor="countryCode">Code <span className="text-danger">*</span></label>
                        </div>
                        <div className="form-floating w-75">
                            <input type="text" className="form-control" name="mobNo" id="mobNo" placeholder="Mobile Number" required 
                                pattern='[0-9]{10}' maxLength={'10'} />
                            <label htmlFor="mobNo">Mobile Number <span className="text-danger">*</span></label>
                        </div>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" name="emailId" id="emailId" placeholder="name@example.com" required maxLength={'80'} />
                        <label htmlFor="emailId">Email Address <span className="text-danger">*</span></label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control"name="compName" id="compName" placeholder="Company Name" maxLength={'50'} />
                        <label htmlFor="compName">Company Name</label>
                    </div>
                    <div className="form-floating mb-3">
                        <select className="form-select" name="productQuote" id="productQuote" aria-label="Select Product For Quotation" required>
                            <option value="1">eCommerce Solutions</option>
                            <option value="2">Web Apps Development</option>
                            <option value="3">Android Apps Development</option>
                        </select>
                        <label htmlFor="productQuote">Quote For <span className="text-danger">*</span></label>
                    </div>
                    <div>
                        <button type='submit' className='btn btn-success'>Request Quote</button>
                    </div>
                </form>
            </div>
        </section>
    </>
  )
}
