import React from 'react'

export default function Teams() {
  return (
    <section id="team" className="team section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Team</h2>
          <h3>Our Hardworking <span>Team</span></h3>
          <p>Meet Our Hardworking Team: Passionate Experts Driving Innovation and Excellence.</p>
        </div>

        <div className="row">

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/shiekh.jpeg" className="img-fluid" alt="Waihheed SHiekh" />
                <div className="social">
                  <a href="https://www.facebook.com/ammirshiekh" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a>
                  <a href="https://www.instagram.com/ammirshiekh/" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a>
                  <a href="https://www.linkedin.com/in/waihheed/" target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Waihheed Shiekh</h4>
                <span>Founder & CEO</span>
              </div>
            </div>
          </div>

          {/* <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="">
                <div className="social">
                  <a href="" target="_blank"><i className="bi bi-facebook"></i></a>
                  <a href="" target="_blank"><i className="bi bi-instagram"></i></a>
                  <a href="" target="_blank"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Mudasir Nazir</h4>
                <span>Chief Operating Officer</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-3.jpg" className="img-fluid" alt="">
                <div className="social">
                  <a href="" target="_blank"><i className="bi bi-facebook"></i></a>
                  <a href="" target="_blank"><i className="bi bi-instagram"></i></a>
                  <a href="" target="_blank"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Anees Akbar</h4>
                <span>Chief Technology Officer</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-2.jpg" className="img-fluid" alt="">
                <div className="social">
                  <a href="" target="_blank"><i className="bi bi-facebook"></i></a>
                  <a href="" target="_blank"><i className="bi bi-instagram"></i></a>
                  <a href="" target="_blank"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Dr. Mohsina Ishrat</h4>
                <span>Chief Financial Officer</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <div className="member">
              <div className="member-img">
                <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="">
                <div className="social">
                  <a href="" target="_blank"><i className="bi bi-facebook"></i></a>
                  <a href="" target="_blank"><i className="bi bi-instagram"></i></a>
                  <a href="" target="_blank"><i className="bi bi-linkedin"></i></a>
                </div>
              </div>
              <div className="member-info">
                <h4>Zahid Kirmani</h4>
                <span>Chief Marketing Officer</span>
              </div>
            </div>
          </div> */}

        </div>

      </div>
    </section>
  )
}
