import React from 'react'

export default function Maintenance() {
  return (
    <section id="maintenance" className="about section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Maintenance</h2>
          <h3><span>Barakah</span>Byte<span>.</span> Under <span>Maintenance</span></h3>
        </div>

        <div className="row">
          <div className="col-lg-6" data-aos="fade-right" data-aos-delay="100">
            <img src="assets/img/maintenance.jpg" className="img-fluid" alt="Maintenance" />
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <h3>We are Currently Performing Maintenance.</h3>
            <p className="fst-italic">
              Thank you for visiting. Our website is currently undergoing scheduled maintenance to improve your experience.
              We apologize for any inconvenience and appreciate your patience.
              Please check back soon. If you need immediate assistance, feel free to contact us.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
