import React from 'react'
import { Link } from 'react-router-dom'

export default function Portfolio() {
  return (
    <>
        <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>Portfolio</h2>
                <ol>
                    <li><Link to="/">Home</Link></li>
                    <li>Portfolio</li>
                </ol>
                </div>

            </div>
        </section>
        <section className="inner-page">
            <div className="container">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Project Name</th><th>Category</th><th>Type</th><th>Date</th><th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><Link to="/PortFolioDetails?ProjName=UPSMMD">UPSMMD</Link></td><td>Education</td>
                            <td>Web Application</td><td>14-May-2023</td><td>Completed</td>
                            </tr>
                            <tr>
                            <td><Link to="/PortFolioDetails?ProjName=BarakahBay">BarakahBay</Link></td><td>eCommerce</td>
                            <td>Web, Android Application</td><td>31-Mar-2025</td><td>Open</td>
                            </tr>
                            <tr>
                            <td><Link to="/PortFolioDetails?ProjName=BarakahEdu">BarakahEdu</Link></td><td>Education</td>
                            <td>SaaS Web Application</td><td>31-Aug-2025</td><td>Open</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </>
  )
}
