import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
        <footer id="footer">
            <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <h4>Join Our Newsletter</h4>
                            <p>Stay Informed: Join Our Newsletter for Exclusive Updates and Offers.</p>
                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" disabled />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h3><span>Barakah</span>Byte<span>.</span></h3>
                            <p>
                                Muqam Dardpora, Kralpora <br />
                                Kupwara, JK 193224<br />
                                India <br /><br />
                                <strong>Phone:</strong> +91 9797033565<br />
                                <strong>Email:</strong> info@barakahbyte.in<br />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/OurPortfolios">Portfolio</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/OurServices">Services</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/UnderMaintenance">Terms of service</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/UnderMaintenance">Privacy policy</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/UnderMaintenance">Barakah Bay</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/UnderMaintenance">Web Development</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/UnderMaintenance">App Development</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/UnderMaintenance">Cloud Computing</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/UnderMaintenance">IT Consulting</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Social Networks</h4>
                            <p>Stay Connected: Follow Us for Latest Updates and Engaging Content.</p>
                            <div className="social-links mt-3">
                                <button className="twitter btn btn-primary"><i className="bx bxl-twitter"></i></button>
                                <button className="facebook btn btn-primary"><i className="bx bxl-facebook"></i></button>
                                <button className="instagram btn btn-primary"><i className="bx bxl-instagram"></i></button>
                                <button className="google-plus btn btn-primary"><i className="bx bxl-skype"></i></button>
                                <button className="linkedin btn btn-primary"><i className="bx bxl-linkedin"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-4">
                <div className="copyright">
                    &copy; Copyright 2024 <strong><span>Barakah</span>Byte</strong>. All Rights Reserved
                </div>
                <div className="credits">
                    Designed by <a href="https://barakahbyte.in/">BarakahByte</a>
                </div>
            </div>
        </footer>
    </>
  )
}
