import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function PortfolioDetails() {
    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);
        const detailsContainer = document.getElementById("portfolio-details");
        const defContainer = document.getElementById("defaultContainer");
        if(params.has('ProjName')){
          const data = {
            UPSMMD: {
              Name: "UPSMMD",
              Category: 'Education',
              Type: 'Web Application',
              Date: '14-May-2023',
              Status: 'Completed',
              Client: 'Govt. UPS Muqdam Mohalla Dardpora',
              Images: [],
              Url: 'https://upsmmd.rf.gd',
              Desc: "UPSMMD is an enterprise level Web Application developed for Govt. Upper Primary School Muqdam Mohalla Dardpora. It streamlines the process from Online Admission to Managing Students record."
            },
            BarakahBay: {
              Name: "BarakahBay",
              Category: 'eCommerce',
              Type: 'Web, Android Application',
              Date: '31-Mar-2025',
              Status: 'Open',
              Client: 'BarakahByte',
              Images: [],
              Url: 'https://bbay.barakahbyte.in',
              Desc: "BarakahBay is an eCommerce web application to cater the Grocery related services online. Its under dev, will be published before 31, March 2025"
            },
            BarakahEdu: {
              Name: "BarakahEdu",
              Category: 'Education',
              Type: 'SaaS Web Application',
              Date: '31-Aug-2025',
              Status: 'Open',
              Client: 'BarakahByte',
              Images: [],
              Url: 'https://bedu.barakahbyte.in',
              Desc: "BarakahEdu is a Software as a Service web application to onboard all the schools from Pre - Higher Secondary, providing Enterprise level functionality of managing all the details in a single go. "
            }
          };
          const projName = params.get('ProjName');
          if(data[projName] !== undefined){
            document.getElementById("projCat").innerText = data[projName]?.Category ?? "Category Not Found";
            document.getElementById("projClient").innerText = data[projName]?.Client ?? "Client Not Found";
            document.getElementById("projDate").innerText = data[projName]?.Date ?? "Project Date Not Found";
            document.getElementById("projUrl").innerText = data[projName]?.Url ?? "Project Name Not Found";
            document.getElementById("projName").innerHTML = data[projName]?.Name ?? "Project Name Not Found";
            document.getElementById("projDesc").innerHTML = data[projName]?.Desc ?? "Description Not Found";
            defContainer.style.display = "none";
            detailsContainer.style.display = "block";
          }else{
            document.getElementById("errMsg").innerHTML = "There is no such project associated to BarakahByte";
            defContainer.style.display = "block";
            detailsContainer.style.display = "none";
          }
        }else{
          defContainer.style.display = "block";
          detailsContainer.style.display = "none";
        }
    });
  return (
    <>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>Portfolio Details</h2>
                    <ol>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/OurPortfolios">Portfolio</Link></li>
                        <li>Portfolio Details</li>
                    </ol>
                </div>
            </div>
        </section>
        <section className="inner-page" id="defaultContainer">
            <div className="container">
                <h2 className="display-2 text-center text-danger">Un-Authorized Access</h2>
                <p id="errMsg">You have accessed an invalid Page</p>
            </div>
            </section>
            <section id="portfolio-details" className="portfolio-details" style={{display: 'none'}}>
            <div className="container">

                <div className="row gy-4">

                    <div className="col-lg-8">
                        <div className="portfolio-details-slider swiper">
                          <div className="swiper-wrapper align-items-center">

                              <div className="swiper-slide">
                                  <img src="assets/img/portfolio/portfolio-details-1.jpg" alt="Product" />
                              </div>

                              <div className="swiper-slide">
                              <img src="assets/img/portfolio/portfolio-details-2.jpg" alt="Product" />
                              </div>

                              <div className="swiper-slide">
                              <img src="assets/img/portfolio/portfolio-details-3.jpg" alt="Product" />
                              </div>

                          </div>
                          <div className="swiper-pagination"></div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="portfolio-info">
                          <h2>Project information</h2>
                          <ul>
                              <li><strong>Category</strong>: <span id="projCat"></span></li>
                              <li><strong>Client</strong>: <span id="projClient"></span></li>
                              <li><strong>Project date</strong>: <span id="projDate"></span></li>
                              <li><strong>Project URL</strong>: <span id="projUrl">Visit Site</span></li>
                          </ul>
                        </div>
                        <div className="portfolio-description">
                          <h3 id="projName">Project Name</h3>
                          <p id="projDesc">Project Description</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    </>
  )
}
