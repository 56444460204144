import React from 'react'

export default function Services() {
  return (
    <section id="services" className="services">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Services</h2>
          <h3>Check our <span>Services</span></h3>
          <p>BarakahByte: Empowering Growth Through Tailored IT Solutions and Services.</p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bx bxl-dribbble"></i></div>
              <h4>Website Development & Design</h4>
              <p>Elevate your online presence with custom-built, visually stunning websites tailored to your brand and audience.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-file"></i></div>
              <h4>E-commerce Solutions</h4>
              <p>Launch and manage your online store effortlessly with our comprehensive e-commerce solutions, including platform setup, 
                customization, and optimization.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-tachometer"></i></div>
              <h4>Mobile App Development</h4>
              <p>Reach customers on their smartphones and tablets with engaging and user-friendly mobile applications crafted to 
                enhance user experience and drive engagement.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-world"></i></div>
              <h4>Cloud Computing Solutions</h4>
              <p>Leverage the power of the cloud to enhance scalability, flexibility, and efficiency in your business operations with our 
                tailored cloud computing solutions.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-slideshow"></i></div>
              <h4>Software Development</h4>
              <p>Transform your ideas into reality with our custom software development services, designed to address your unique business 
                needs and challenges.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
            <div className="icon-box">
              <div className="icon"><i className="bx bx-arch"></i></div>
              <h4>IT Consulting</h4>
              <p>Gain strategic insights and expert guidance from our experienced IT consultants, helping you make informed decisions to 
                optimize your IT infrastructure and drive business growth.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
  )
}
