import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Contacts from './components/Contacts';
import Footer from './components/Footer';
import Home from './components/Home';
import NavBar from './components/NavBar';
import Services from './components/Services';
import Teams from './components/Teams';
import TopBar from './components/TopBar';
import Maintenance from './components/Maintenance';
import Portfolio from './components/Portfolio';
import PortfolioDetails from './components/PortfolioDetails';
import RequestQuote from './components/RequestQuote';

function App() {
  return (
    <Router>
      <TopBar />
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/AboutUs' element={<About />} />
        <Route path='/OurServices' element={<Services />} />
        <Route path='/Teams' element={<Teams />} />
        <Route path='/ContactUs' element={<Contacts />} />
        <Route path='/UnderMaintenance' element={<Maintenance />} />
        <Route path='/OurPortfolios' element={<Portfolio />} />
        <Route path='/PortFolioDetails' element={<PortfolioDetails />} />
        <Route path='/RequestQuotes' element={<RequestQuote />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
